@charset "UTF-8";
/*
Theme Name: ""
Description:""
Theme URI: www/
Author: MS
Author URI: www/
Version: 1
*/

@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/earlyaccess/hannari.css');

@import "reset.css";

$col_theme_be01:#6B6B52;
$col_theme_be02:#9D9D80;
$col_theme_be03:#ABAB92;
$col_theme_be04:#CECEBF;
$col_theme_be05:#E6E6DF;
$col_theme_be06:#F5F5F2;



$col_white:#ffffff;
$col_dgray:#555555;
$col_gray:#cccccc;
$col_lgray:#f4f4f4;


$col_red:#d90000;

$col_green:#33995e;
$col_mgreen:#799933;
$col_link:#339999;
$col_yellow:#fdd000;
$col_brown:#996c33;
$col_wine:#983333;

$col_text:#111111;
$col_a:#1a0dab;
$col_a_visited:#660099;

$bg_dgray:#666666;
$bg_gray:#999999;
$bg_lgray:#cccccc;





$contet_width:1200px;


$font_serif:"游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$font_sunserif:"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;

$font_shippori: 'Shippori Mincho B1', serif;
$font_hannari: 'Hannari', 'Shippori Mincho B1', serif;

@font-face {
	font-family: 'Antro_Vectra'; /* フォント名 */
	/* フォーマットごとにパスを指定 */
	src: url('../font/Antro_Vectra.woff2') format('woff2');
	src: url('../font/Antro_Vectra.woff') format('woff');
	src: url('../font/Antro_Vectra.ttf') format('truetype');
	font-display: swap;
}
$font_antro_vectra: 'Antro_Vectra', serif;

@font-face {
	font-family: 'karakaze-R'; /* フォント名 */
	/* フォーマットごとにパスを指定 */
	src: url('../font/karakaze-R.woff2') format('woff2');
	src: url('../font/karakaze-R.woff') format('woff');
	src: url('../font/karakaze-R.ttf') format('truetype');
	font-display: swap;
}
$font_karakaze_r: 'karakaze-R', serif;


@mixin center {
	margin:0 auto;
	text-align:center;
}
@mixin hover_transition {
	-webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}
@mixin hover {
	filter:alpha(opacity=60);
	-moz-opacity: 0.60;
	opacity: 0.60;
	@include hover_transition;
}







a{
	color:$col_a;
	text-decoration	: none;
	@include hover_transition;
	
	&:link
	,&:visited {
		color: $col_a_visited;
		text-decoration	: none;
	}

	&:hover
	,&:focus
	,&:active {
		text-decoration	: underline;
		outline: none;
		@include hover_transition;
	}
}



a:hover img {
	@include hover;
}

a.non-hover:hover img {
	filter:alpha(opacity=100);
	-moz-opacity: 1.0;
	opacity: 1.0;
}





.cf:before,
.cf:after {
	content: "";
	display: block;
	overflow: hidden;
}
.cf:after {
	clear: both;
}
.cf {
	zoom: 1;
}





@keyframes bg_header_blur {
	0% {
		-webkit-filter: blur(4px);
		-moz-filter: blur(4px);
		-o-filter: blur(4px);
		-ms-filter: blur(4px);
		filter: blur(4px);
	}
	100% {
		-webkit-filter: blur(0px);
		-moz-filter: blur(0px);
		-o-filter: blur(0px);
		-ms-filter: blur(0px);
		filter: blur(0px);
	}
}



.sp_only{
	display: none;
}
.pc_only{
	display: block;
}
.sp_only_inline{
	display: none;
}
.pc_only_inline{
	display: inline-block;
}









body {
	margin: 0;
	padding: 0px;
	font-family: $font_serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 100%;
	color: $col_text;
	background-color: $col_white;
	// font-feature-settings: "pkna";
	font-feature-settings: "pkna" 1;
	letter-spacing: 0.05em;
}




/*-------------------------------------------------
	++ HEADER
-------------------------------------------------*/
header#site_header{
	width:100%;

	display: flex;
	justify-content: space-between;
	position: fixed;
	z-index: 10;

	min-height: 80px;
	// background-color: rgba(#fff,0.5);
	background-color: #fff;
	// border-bottom: solid 1px $col_theme_be06;

	&.rgba_50{
		background-color: rgba(#fff,0.5);
	}


	#site_name{
		display: block;
		clear: both;
		background: url( "../img/logo_gram_01_bk.svg" ) no-repeat 50% 50%;
		background-size: contain;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		width: 160px;
		height: 54px;
		margin: 16px 0 0px 30px;
	}


	/*-------------------------------------------------
	++ NAV
	-------------------------------------------------*/
	nav{
		// background-color: #ee0;
		ul{

			display: flex;
			justify-content: space-between;
			// align-items: center;
			flex-wrap: wrap;
			text-align: center;
			letter-spacing: 0.2em;
			li{
				// background-color: #e7e;
				display: inline-block;
				a
				,a:link
				,a:visited {
					font-size: 14px;
					line-height: 100%;
					font-weight: 500;
					display: block;
					padding: 33px 16px 33px 16px;
					// background-color: #eee;
					height: 100%;
					color: #000;
					letter-spacing: 0.15em;
					position: relative;

					.txt_jp{
						display: none;
						position: absolute;
						bottom: 12px;
						left: 0;
						text-align: center;
						width: 100%;
						white-space: nowrap;
						font-size: 10px;
						// background-color: #e9e;
					}


					&:hover
					,&:focus
					,&:active {
						background-color: $col_theme_be06;
						text-decoration: none;
						.txt_jp{
							display: block;
						}
					}
				}
				// @at-root .home header#site_header nav ul li.top{
				// 	border-bottom: solid 1px $col_theme_be02;
				// 	a
				// 	,a:link
				// 	,a:visited {
				// 		background-color: $col_theme_be05;
				// 		.txt_jp{
				// 			display: block;
				// 		}
				// 	}
				// }
				&.current-menu-item{
					border-bottom: solid 1px $col_theme_be02;
					a
					,a:link
					,a:visited {
						background-color: $col_theme_be05;
						.txt_jp{
							display: block;
						}
					}
				}
				@at-root .single-works header#site_header nav ul li.works{
					border-bottom: solid 1px $col_theme_be02;
					a
					,a:link
					,a:visited {
						background-color: $col_theme_be05;
						.txt_jp{
							display: block;
						}
					}
				}
				@at-root .single-price header#site_header nav ul li.price{
					border-bottom: solid 1px $col_theme_be02;
					a
					,a:link
					,a:visited {
						background-color: $col_theme_be05;
						.txt_jp{
							display: block;
						}
					}
				}
				@at-root .single-post header#site_header nav ul li.news{
					border-bottom: solid 1px $col_theme_be02;
					a
					,a:link
					,a:visited {
						background-color: $col_theme_be05;
						.txt_jp{
							display: block;
						}
					}
				}
				@at-root .single-column header#site_header nav ul li.column{
					border-bottom: solid 1px $col_theme_be02;
					a
					,a:link
					,a:visited {
						background-color: $col_theme_be05;
						.txt_jp{
							display: block;
						}
					}
				}
				&.link_red{
					// margin-left: 10px;
					border-bottom: none;
					a
					,a:link
					,a:visited {
						background-color: $col_red;
						color: #fff;
						font-size: 18px;
						padding: 20px 30px;
						.txt_jp{
							font-size: 14px;
							margin-top: 4px;
							position: static;
							display: block;
						}

						&:hover
						,&:focus
						,&:active {
							background-color: darken($col_red, 10%);
						}
					}
				}
			}
		}
	}

}



.logomark{

    position: relative;
    &.pic_shadow_light{
    	transition: 0.5s;
		filter: drop-shadow(1px 0px 1px rgba(#000,0.05)) 
			drop-shadow(0px 1px 2px rgba(#000,0.05)) 
			// drop-shadow(0px -1px 2px rgba(#fff,0.8)) 
			drop-shadow(0px 3px 4px rgba(#000,0.15));
	}
	&.pic_shadow_dark{
    	transition: 0.5s;
		filter: drop-shadow(1px 0px 1px rgba(#000,0.1)) 
			drop-shadow(0px 1px 2px rgba(#000,0.1)) 
			drop-shadow(0px -1px 2px rgba(#000,0.1)) 
			drop-shadow(0px 3px 2px rgba(#000,0.3));
	}
}
.mask_logo_def{
	width: 32vw;
	// min-width: 460px;
	// min-height: 460px;
	mask-image: url("../img/logomark_gram_bk.svg");
	mask-repeat: no-repeat;
	mask-position: 0 0;
	mask-size: 100%;
	/* Chrome, Safari用 */
	-webkit-mask-image: url("../img/logomark_gram_bk.svg");
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: 50% 50%;
	-webkit-mask-size: 100%;
	// transition: 3s;
	position: absolute;
	z-index: 2;
	// opacity: 0;
	display:none;

}
.mask_logo_pic {
	width: 32vw;
	// min-width: 460px;
	// min-height: 460px;
	mask-image: url("../img/logomark_gram_bk.svg");
	mask-repeat: no-repeat;
	mask-position: 0 0;
	mask-size: 100%;
	/* Chrome, Safari用 */
	-webkit-mask-image: url("../img/logomark_gram_bk.svg");
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: 50% 50%;
	-webkit-mask-size: 100%;
	transition: 3s;
	position: absolute;
	z-index: 1;
	display:none;
    // left: 10px;

}



/*-------------------------------------------------
	++ HOME
-------------------------------------------------*/

#main_image{
	background-color: $col_theme_be06;
	width: 100%;
	height: 100vh;
	max-height: 1000px;
	padding: 180px 0 100px 0;
	overflow: hidden;
	.main_image_inner{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		margin: 0px auto;
		.left{
			// width: 500px;
			// height: 500px;
			margin-right: 2vw;
			position: relative;
			margin-left: 9vw;
			.logomark_outer{
				position: relative;

				.box_illust_person{
					// border: solid 1px;
					position: absolute;
					width: 12vw;
					height: 12vw;
					// min-width: 180px;
					// min-height: 180px;
					left: calc(50% - 90px - 3.4vw * 0.9);
					top: calc(50% - 90px - 2vw * 0.9);

					left: 9vw;
					top: 8vw;
					z-index: 1;
					opacity: 0;
					svg{
						width: 100%;
						height: auto;
					}
				}
			}
			.logomark{
				width: 32vw;
				height: 32vw;
				// min-width: 460px;
				// min-height: 460px;
				margin-left: 3vw;
				z-index: 2;
			}

			.txt_texture{
				display: none;
				position: absolute;
				left: 1vw;
				bottom: 1.2vw;
				// font-family: $font_hannari;
				font-size: 13px;
				line-height: 140%;
				letter-spacing: 0em;
				text-align: left;
				// background-color: #eee;
				width: 240px;
				height: 100px;

				.txt_texture_inner{
					display: flex;
					// justify-content: center;
					align-items: center;
					height: 100px;
				}
				strong{
					font-size: 18px;
					line-height: 140%;
				}
				.txt_texture_comment{
					margin-top: 2px;
					display: inline-block;
					line-height: 160%;
				}
			}

		}
		.capthcopy{
			width: 48vw;
			min-width: 600px;
			font-family: $font_shippori;

			strong.sub_copy{
				font-size: 20px;
				line-height: 100%;
				letter-spacing: 0.09em;
				font-weight: 600;
				margin: 8px 0 8px 4px;
				// margin-left: 4px;
				display: inline-block;
			}
			h2{
				font-size: 50px;
				line-height: 100%;
				font-weight: 500;
				font-family: $font_hannari;
				// font-family: $font_karakaze_r;
				margin: -12px 0 6px;
				letter-spacing: 0.175em;
				display: inline-block;
				.txt_s{
					font-size: 40px;
					line-height: 100%;
					margin-bottom: 3px;
					display: inline-block;
					vertical-align: bottom;
				}
			}
			.box_h2{
				// background-color: #e98;
				display: inline-block;
				margin: 12px 0 28px;
			}
			p{
				font-size: 22px;
				line-height: 100%;
				letter-spacing: 0.12em;
				font-weight: 500;
				margin: 2px 0px 6px 4px;
				display: inline-block;
				strong{
					background:linear-gradient(90deg, #F7C1C2, #F7C1C2);
					background-repeat: no-repeat;
					background-position: bottom left;
					background-size: 0 30%; /* '30%'の部分にマーカーの太さを記入 */
					// background:linear-gradient(transparent 60%, #F7C1C2 60%);
					padding: 0 4px;
					margin: 6px 4px 6px 4px;
					transition: all 0.2s ease-in-out;
					// background-size: 0 60%;
					&.ml0{
						margin-left: 0;
					}

					/* アニメーション発火時 */
					&.inview {
						background-size: 100% 30%; /* '30%'の部分は上で設定した太さに合わせる */
					}
				}


			}
			.box_p{
				margin: 24px 0;
				// background-color: #e98;
			}
		}
	}
}





/*-------------------------------------------------
	++ CONTENT BASE
-------------------------------------------------*/



/*  Loading
--------------------------------------- */
#page-wrap{
	position: relative;
}
#page-loader {
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: #ffffff;
	z-index: 100000;
	.loader {
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -25px 0 0 -25px;
		font-size: 10px;
		text-indent: -12345px;
		border-top: 1px solid rgba(0,0,0, 0.2);
		border-right: 1px solid rgba(0,0,0, 0.2);
		border-bottom: 1px solid rgba(0,0,0, 0.2);
		border-left: 1px solid rgba(0,0,0, 1);
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		-webkit-animation: spinner 700ms infinite linear;
		-moz-animation: spinner 700ms infinite linear;
		-ms-animation: spinner 700ms infinite linear;
		-o-animation: spinner 700ms infinite linear;
		animation: spinner 700ms infinite linear;
		z-index: 100001;
	}



}


@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}









.home #contener{
	padding-top: 0px;
}
#contener{
	position: relative;
    padding-top: 80px;

	.page_header_wall{
		position: relative;
		.bg_header{
			color: #ffffff;
			width: 100%;
			height: 100%;
			background:url("../img/bg_header_news.png") no-repeat center;
			background-size: cover;
			position: absolute;
			top: 0;
			z-index: 0;
			overflow: hidden;
			&:before{
				content: '';
				background: inherit;
				-webkit-filter: blur(4px);
				-moz-filter: blur(4px);
				-o-filter: blur(4px);
				-ms-filter: blur(4px);
				filter: blur(4px);

				position: absolute;
				top: -4px;
				left: -4px;
				right: -4px;
				bottom: -4px;
				z-index: -1;
				animation-name: bg_header_blur;
				animation-duration: 1s;
				animation-delay: 2s;				//アニメーション開始までの時間(秒)
				animation-iteration-count: 1;		//アニメーションを再生する回数
				animation-fill-mode: forwards;		//アニメーションの終了後には最後のキーフレームのスタイルを適用する*
			}
		}
	}


	.page_header{
		width: 100%;
		height: 240px;
		// background-color: #ededed;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		.page_header_title{
			margin: 8px 0 0 0;
			font-size: 50px;
			line-height: 100%;
			text-align: center;
			letter-spacing: 0.3em;
			display: inline-block;
			padding-left: 0.3em;
			.ttl_jp{
				font-size: 20px;
				line-height: 100%;
				display: block;
				margin-top: 12px;
				letter-spacing: 0.3em;
				padding-left: 0.9em;
			}
		}
	}
	#sec_status{
		margin-top: 80px;
		margin-bottom: 80px;
		border-radius: 12px;
		background-color: rgba($col_theme_be04,0.2);
		box-shadow: 0 2px 4px rgba(#000,0.1)
			, 0 -1px 1px rgba(#000,0.04)
			, 0 1px 1px rgba(#000,0.05)
			,1px 0 1px rgba(#000,0.1)
			,-1px 0 1px rgba(#000,0.1);
		padding: 20px;
		position: relative;
		.sec_status_dot::after
		,.sec_status_dot::before
		,&::after
		,&::before{
			content: "";
			width: 5px;
			height: 5px;
			background-color: $col_theme_be04;
			position: absolute;
			border-radius: 100%;
		}
		&::after{
			top: 9px;
			left: 9px;
		}
		&::before{
			top: 9px;
			right: 9px;
		}
		.sec_status_dot::after{
			bottom: 9px;
			left: 9px;
		}
		.sec_status_dot::before{
			bottom: 9px;
			right: 9px;
		}

		.sec_status_inner{
			background-color: rgba(#fff,0.9);
			padding: 20px 30px;
			box-shadow: 0 1px 2px rgba(#000,0.05)
			,1px 0 1px rgba(#000,0.05)
			,-1px 0 1px rgba(#000,0.05);

			display: flex;
			align-items: stretch;
			justify-content: space-between;

			font-family: $font_serif;

			.block_status01{
				width: 120px;
				margin-left: 10px;
				h2{
					color: $col_theme_be03;
					border: solid 1px $col_theme_be03;
					padding-left: 0.5em;
					padding: 16px 0 16px 0.5em;
					margin: 0;
					letter-spacing: 0.5em;
					font-size: 28px;
					font-weight: 500;
				}
				.txt_update{
					font-size: 12px;
					font-weight: 400;
					line-height: 100%;
					letter-spacing: 0;
					margin-top: 8px;
					// font-family: $font_sunserif;
				}
			}
			.block_status02{
				width: 240px;
				// border-top: solid 1px $col_theme_be05;
				// border-bottom: solid 1px $col_theme_be05;
				padding: 4px 8px;
				p{
					font-size: 14px;
					line-height: 220%;
					margin: 0px;
					height: 100%;
					display: flex;
					align-items: center;
					text-align: justify;
					text-justify: inter-ideograph;
					letter-spacing: 0em;
				}
			}
			.block_status03{
				width: 240px;
				table{
					width: 100%;
					tr{
						td{
							border-bottom: solid 1px $col_theme_be05;
							text-align: left;

							font-weight: 400;
							font-size: 14px;
							line-height: 120%;
							padding-top: 8px;
							padding-bottom: 0px;
							padding: 10px 8px 0px 4px;
							&.td_lbl{
								padding-bottom: 2px;
							}
							&.td_val{
								text-align: right;
								.txt_val{
									font-size: 24px;
									line-height: 80%;
									font-weight: 400;
									display: inline-block;
									vertical-align: bottom;
									padding-bottom: 1px;
								}
								.txt_unit{
									font-size: 14px;
									line-height: 120%;
									font-weight: 400;
									display: inline-block;
									vertical-align: bottom;
									padding-bottom: 0px;
									margin-left: 2px;
								}
							}
						}
					}
				}
			}
			.block_status04{
				width: 360px;
				.chart{

					margin-top: 10px;

					display: flex;
					align-items: flex-end;

					.chart_bg{
						width: 220px;
						margin: 0 10px 2px 4px;

						width: 220px;
						height: 30px;
						background: url( "../img/chart_bar_bg.svg" ) no-repeat 50% 100%;
						background-size: contain;
						.chart_bar_mask{
							overflow: hidden;
						}
						.chart_bar{
							width: 220px;
							height: 30px;
							background: url( "../img/chart_bar_red.svg" ) no-repeat 50% 100%;
							background-size: contain;
						}
					}

					.txt_chart_val{
						font-size: 26px;
						line-height: 80%;
						letter-spacing: 0;
						.txt_unit{
							font-size: 14px;
							line-height: 100%;
							font-weight: 500;
							margin-left: 2px;
							display: inline-block;
							margin-bottom: 1px;
							vertical-align: bottom;
						}
					}

				}
				h3{
					margin-top: 0;
					font-size: 14px;
					line-height: 120%;
				}

				.message{
					display: flex;
					align-items: center;

					margin-top: 10px;
					height: 80px;
					.message_pic{
						margin-top: 10px;
						img{
							width: 100px;
						}
					}

					p{
						font-size: 14px;
						line-height: 200%;
						font-weight: 500;
						text-align: left;
						margin: 0 0 0 10px;
						strong{
							background:linear-gradient(transparent 60%, #FFF8AA 60%);
							padding: 0 4px;
							margin: 0 4px;
						}
					}
				}
			}

			.block_status_hr{
				border-left: solid 1px $col_theme_be04;

			}
		}
	}

	#contents {
		@include center;
		padding: 0px 0px 140px 0px;
		// width: $contet_width;
		position: relative;

		// font-family: $font_sunserif;
		font-family: $font_serif;
		font-size: 15px;
		line-height: 160%;


		.pankuzu{
			font-family: $font_shippori;
			font-size: 13px;
			line-height: 120%;
			margin-top: 6px;
			margin-bottom: 20px;
			text-align: left;
			border-bottom: solid 1px $col_theme_be04;
			// padding-bottom: 4px;
			padding: 0 8px 6px 8px;
			a{
				color:$col_text;
			}
		}



		.box_left_right{
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			text-align: left;
			.block_ttl{
				width: 400px;

				h2{
					font-size: 50px;
					line-height: 100%;
					letter-spacing: 0.3em;
					margin: 8px 0 0 0;
					.ttl_jp{
						font-family: $font_shippori;
						font-size: 20px;
						line-height: 100%;
						display: block;
						margin: 8px 0 0 4px;
						font-weight: 500;
					}
				}
				p{
					font-family: $font_shippori;
					font-size: 16px;
					line-height: 200%;
					margin-top: 48px;
				}
				.sec_icatch{
					margin-top: 40px;
				}
			}
			.block_content{
				width: calc(100% - 400px);
			}
		}





		#sec_commit{
			// margin-top: 20px;
			padding-top: 118px;
			@at-root .admin-bar #contener #contents #sec_commit{
				padding-top: 149px;
			}
			.block_content{
				h3{
					margin: 0px 0 20px -10px;
					font-family: $font_shippori;
					// font-family: $font_serif;
					font-size: 32px;
					line-height: 100%;
					font-weight: 400;
					letter-spacing: 0.15em;
					.font_antro_vectra{
						font-size: 120px;
						line-height: 100%;
						font-weight: 400;
						letter-spacing: 0em;
						vertical-align: middle;
						display: inline-block;
						margin-bottom: -30px;
						margin-right: 16px;
					}
				}
				.commit_txt{
					margin-top: 40px;
					p{
						// font-family: $font_shippori;
						font-family: $font_serif;
						font-size: 16px;
						line-height: 240%;
						margin-bottom: 20px;
						font-weight: 400;
						letter-spacing: 0.12em;
					}
				}

				.profile{
					margin-top: 40px;
					display: flex;
					align-items: stretch;
					justify-content: space-between;
					.profile_photo{
						width: 160px;
						img{
							width: 140px;
							border-radius: 100%;
						}
					}
					.profile_txt{
						width: calc(100% - 160px);
						margin-left: 20px;
						margin-top: 28px;
						.txt_post{
							font-size: 12px;
							line-height: 100%;
							letter-spacing: 0.3em;
							font-family: $font_serif;
						}
						.txt_name{
							display: flex;
							align-items: center;
							justify-content: start;
							margin-top: 6px;
							.txt_name_jp{
								font-size: 24px;
								line-height: 100%;
								font-family: $font_serif;
								letter-spacing: 0.4em;
								font-weight: 400;
							}
							.txt_name_en{
								font-family: $font_antro_vectra;
								font-size: 32px;
								line-height: 100%;
								margin-top: 8px;
								margin-left: 40px;
							}
						}
						p{
							font-size: 14px;
							line-height: 200%;
						}
					}
				}
			}
		}
		#sec_service{
			margin-top: 132px;
			padding-top: 68px;
			@at-root .admin-bar #contener #contents #sec_service{
				padding-top: 99px;
			}
			.block_content{
				margin-top: 12px;
				h3{
					font-size: 40px;
					line-height: 100%;
					letter-spacing: 0.2em;
					margin-left: 2px;
					.lbl_jp{
						font-size: 16px;
						line-height: 100%;
						font-weight: 500;
						vertical-align: top;
						display: inline-block;
						margin-top: 14px;
						margin-left: 20px;
						letter-spacing: 0.1em;
					}
				}
			}
		}

		#sec_works{
			margin-top: 132px;
			padding-top: 68px;
			@at-root .admin-bar #contener #contents #sec_works{
				padding-top: 99px;
			}
			.block_content{
				margin-top: 12px;
			}
		}

		#sec_price{
			margin-top: 133px;
			padding-top: 68px;
			@at-root .admin-bar #contener #contents #sec_price{
				padding-top: 99px;
			}
			.block_content{
				margin-top: 12px;
				.box_list{
					.block_list{

						h3{
							font-size: 24px;
							line-height: 160%;
							margin-left: 2px;
						}
					}
				}
			}
		}


		#sec_news{
			margin-top: 132px;
			padding-top: 67px;
			@at-root .admin-bar #contener #contents #sec_news{
				padding-top: 100px;
			}
			.block_content{
				margin-top: 12px;
				.box_list{
					.block_list{
						p{
							font-size: 14px;
							line-height: 200%;
							padding-left: 0;
						}
					}
				}
			}
		}

		#sec_column{
			margin-top: 132px;
			padding-top: 68px;
			@at-root .admin-bar #contener #contents #sec_column{
				padding-top: 100px;
			}
			.block_content{
				// margin-top: 12px;
			}
		}



		.width_s{
			width: 640px;
			@include center;
		}
		.width_m{
			width: 800px;
			@include center;
		}
		.width_l{
			width: 1200px;
			@include center;
		}


		.box_list{
			display: flex;
			flex-wrap: wrap;
			border-bottom: solid 1px $col_theme_be04;
			text-align: left;
			a.block_list{
				color: $col_text;
				background: url( "../img/arrow_s_be_r.svg" ) no-repeat 100% 50%;
				background-size: 30px 60px;
				padding: 40px 52px 40px 0;
				&:hover
				,&:focus
				,&:active {
					text-decoration: none;
					background-color: $col_theme_be06;
					background-position: 99% 50%;
					padding: 40px 40px 40px 12px;
				}

			}
			.block_list{
				width: 100%;
				border-top: solid 1px $col_theme_be04;
				padding: 40px 0;
				.lbl_sub{
					font-size: 20px;
					line-height: 100%;
					font-family: $font_shippori;
					margin-bottom: 8px;
					margin-left: 4px;
					letter-spacing: 0.2em;
				}
				.txt_date{
					margin-left: 6px;
					font-size: 12px;
					line-height: 100%;
					font-family: $font_shippori;
					margin-bottom: 8px;
					margin-right: 20px;
					letter-spacing: 0.3em;
					display: inline-block;

				}
				.txt_cat{
					margin-left: 6px;
					font-size: 12px;
					line-height: 100%;
					font-family: $font_shippori;
					margin-bottom: 8px;
					letter-spacing: 0.1em;
					display: inline-block;
					span {
						background-color: $col_theme_be06;
						padding: 2px 8px 4px 8px;
						&.col_works_disp{
							background-color: $col_red;
							color: #fff;
						}
					}
				}
				h3{
					margin: 0;
					margin-left: 4px;
					font-size: 17px;
					line-height: 160%;
					font-family: $font_shippori;
					letter-spacing: 0.1em;
					border:none;
					padding: 0;
					.lbl_jp{
						font-size: 16px;
						line-height: 100%;
						font-weight: 500;
						vertical-align: top;
						display: inline-block;
						margin-top: 14px;
						margin-left: 20px;
						letter-spacing: 0.1em;
					}
				}
				.txt_important{
					display: inline-block;
					background-color: $col_red;
					color: #fff;
					padding: 5px 8px 5px 10px;
					vertical-align: middle;
					margin-top: -5px;
					margin-right: 8px;
					font-size: 80%;
					line-height: 100%;
				}
				p{
					font-family: $font_serif;
					font-size: 15px;
					line-height: 200%;
					font-weight: 400;
					letter-spacing: 0.1em;
					padding-left: 4px;
					margin-top: 16px;
				}
			}
		}


		.box_tail_thumb{
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 12px;

			.block_tail_thumb{
				margin-bottom: 35px;
				width: 30%;
				a.line_frame{
					&:hover
					,&:focus
					,&:active {
						text-decoration: none;
						border: solid 1px $col_theme_be01;
						.line_frame_inner{
							background-color: #fff;
							@include hover_transition;
						}
					}
				}
				.line_frame{
					display: block;
					border: solid 1px $col_theme_be04;
					background-color: #fff;
					padding: 10px;
					text-align: center;
					font-family: $font_serif;
					color: $col_text;
					font-weight: 400;
					font-size: 18px;
					letter-spacing: 0.1em;
					list-style: 140%;

					.line_frame_inner{
						display: flex;
						align-items: center;
						justify-content: center;
						// display: table-cell;
						// vertical-align: middle;
						height: 160px;
						width: 100%;
						background-color: $col_theme_be06;
						@include hover_transition;
					}

				}
				.txt_date{
					font-size: 12px;
					line-height: 120%;
					text-align: right;
					margin-top: 8px;
				}
			}
			&::before{
				content:"";
				display: block;
				width:240px;
				order:1;
			}
			&::after{
				content:"";
				display: block;
				width:240px;
				// order:1;
			}
		}

		.box_list_thumb{
			display: flex;
			flex-wrap: wrap;
			border-bottom: solid 1px $col_theme_be04;
			.block_list_thumb{
				width: 100%;
				border-top: solid 1px $col_theme_be04;
				padding: 40px 0;

				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				a.list_thumb_pic{
					display: inline-block;
					border: solid 1px $col_theme_be04;
					background-color: #fff;
					padding: 10px;
					width: 382px;
					img{
						max-width: 100%;
						vertical-align: bottom;
					}
					&:hover
					,&:focus
					,&:active {
						border: solid 1px $col_theme_be01;
					}
				}
				.list_thumb_txt{
					width: calc(100% - 382px - 32px);
					// width: 400px;

					.txt_entry_date{
						font-size: 12px;
						line-height: 140%;
						margin-top: 10px;
					}
					a{
						h3{
							color: $col_text;
						}

						&:hover
						,&:focus
						,&:active {
							text-decoration: none;
							h3{
								color: $col_red;
							}
						}
					}
					h3{
						margin: 0;
						font-size: 20px;
						line-height: 140%;
						font-family: $font_shippori;
						letter-spacing: 0.1em;
						margin-top: 4px;
						font-weight: 500;
						border-bottom: none;
						padding: 0;
					}
					.txt_client{
						font-size: 14px;
						line-height: 140%;
						margin-top: 12px;
					}
					ul{
						margin-top: 36px;
						li{
							font-family: $font_serif;
							position: relative;
							padding-left: 2em;
							margin: 8px 0;
							font-size: 14px;
							line-height: 160%;
							&:before{
								content: "■";
								font-size: 24px;
								line-height: 1;
								position: absolute;
								top: 0;
								left: 0;
								color: $col_theme_be04;
							}
						}
					}
				}
			}
		}

		.box_link_term{
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 80px;
			.block_link_term{
				// background-color: #fff;
				padding: 10px;
				// border: solid 1px $col_theme_be01;
				width: 30%;
				text-align: center;
				// color: $col_theme_be01;
				font-size: 15px;
			}
		}



		.header_post_title
		,.header_page_title{
			margin-top: 120px;
			margin-bottom: 100px;
			h1{
				margin-top: 12px;
				margin-bottom: 12px;
				font-size: 32px;
				line-height: 160%;
				font-weight: 500;
				border-bottom: none;
				letter-spacing: 0.2em;
			}
			.plan_num{
				font-size: 20px;
				line-height: 100%;
				margin-top: 20px;
				margin-bottom: 12px;
				letter-spacing: 0.2em;
			}
			.plan_outline{
				font-size: 18px;
				line-height: 180%;
				margin-top: 24px;
				margin-bottom: 24px;
				letter-spacing: 0.1em;
			}

			.post_date{
				font-size: 16px;
				line-height: 120%;
				margin-top: 20px;
				margin-bottom: 20px;
			}
			.works_cat{
				font-size: 15px;
				background-color: $col_theme_be06;
				padding: 4px 8px 4px 8px;
				&.col_works_disp{
					background-color: $col_red;
					color: #fff;
				}
			}
		}
		.post_content{
			font-family: $font_sunserif;
		}
		.post_content
		,.page_content{
			text-align: left;
			margin-top: 120px;

			table{
				font-size: 15px;
				border-collapse:  collapse;
				margin: 16px 0;
				th,td{
					border: solid 1px $col_theme_be01;
					padding: 8px;
				}
				th{
					background-color: #eee;
					text-align: center;
				}
				td{
					background-color: #fff;
				}
			}


			p{
				font-size: 16px;
				margin-top: 20px;
				line-height: 220%;
			}


			h2{
				font-size: 24px;
				line-height: 160%;
				border-left: solid 6px $col_theme_be02;
				padding: 0 0 0 8px;
				font-weight: 500;
				margin-top: 80px;
				margin-bottom: 20px;
				&.page_sub_title{
					border-left: none;
					text-align :center;
					margin-top: 0px;
					margin-bottom: 80px;
					font-size: 20px;
				}
			}
			h3{
				font-size: 22px;
				line-height: 160%;
				border-bottom: solid 1px $col_theme_be04;
				padding: 0 2px 0 4px;
				font-weight: 500;
				margin-top: 50px;
				margin-bottom: 20px;

				&.h3_sub{
					font-size: 24px;
					line-height: 120%;
					letter-spacing: 0.2em;
					margin-top: 80px;
					border-bottom: none;
					padding: 0;
					font-weight: 400;
				}
			}
			h4{
				font-size: 20px;
				line-height: 160%;
				font-weight: 500;
				margin-top: 40px;
				margin-bottom: 20px;
			}
			h5{
				font-size: 18px;
				line-height: 160%;
				font-weight: 500;
				margin-top: 30px;
				margin-bottom: 20px;
			}
			h6{
				font-size: 16px;
				line-height: 160%;
				font-weight: 500;
				margin-top: 20px;
				margin-bottom: 20px;
			}
			ul.works_asign{
				margin: 20px 0;
				li{
					position: relative;
					padding-left: 2em;
					margin: 0 0 8px 0;
					&:before{
						content: "■";
						font-size: 24px;
						line-height: 1;
						position: absolute;
						top: 1px;
						left: 0;
						color: $col_theme_be04;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}

			ul.list_icon_check_l{
				margin: 20px 0;
				// margin-left: 1.5em;
				li{
					position: relative;
					font-size: 16px;
					line-height: 160%;
					// font-weight: 800;
					margin-top: 18px;
					padding-left: 1.6em;
					letter-spacing: 0.01em;
					border-bottom: solid 1px $col_theme_be04;
					&::after {
						display: block;
						content: "";
						position: absolute;
						top: 1px;
						left: 0;
						background: url(../img/icon_check.svg);
						background-size: contain;
						width: 1.5em;
						height:1.3em;
					}
				}
			}
			table.tbl_underline{
				width: 100%;
				border-collapse:  collapse;
				margin: 20px 0;
				border: solid 1px $col_theme_be04;
				tr{
					th
					,td{
						// border-top: solid 1px $col_theme_be04;
						// border-bottom: solid 1px $col_theme_be04;

						border: solid 1px $col_theme_be04;
						padding: 20px;
						text-align: left;
					}
					th{
						white-space: nowrap;
					}
					td{
						width: 100%;
					}
				}
			}
			.txt_underline{
				border-bottom: solid 1px $col_theme_be04;
				padding-bottom: 4px;
				display: inline;
			}
			.txt_center{
				text-align: center;
			}
			.txt_ll{
				font-size: 138%;
				// line-height: 120%;
				padding-bottom: 0px;
			}
			.txt_l{
				font-size: 118%;
				// line-height: 140%;
				padding-bottom: 2px;
			}
			.txt_s{
				font-size: 88%;
				// line-height: 140%;
				padding-bottom: 2px;
			}

			.txt_indent_1{
				text-indent: -1em;
				padding-left: 1em;
				line-height: 140%;
				margin-top: 4px;
			}
			table.tbl_works{
				margin-top: 20px;
				margin-bottom: 20px;
				width: 100%;
				
				tr{
					th
					,td{
						border: none;
						border-top: 1px solid rgba($col_theme_be03,0.3);
						border-bottom: 1px solid rgba($col_theme_be03,0.3);
						padding: 20px 20px;
						text-align: left;
						font-size: 16px;
						
						background-color: transparent;
					}
					th{
						width: 1%;
						text-align: right;
						white-space: nowrap;
						border-top: 1px solid rgba($col_theme_be03,1);
						border-bottom: 1px solid rgba($col_theme_be03,1);
					}
					td{
						width: 100%;
					}

				}
			}



			table.tbl_form{
				margin-top: 40px;
				margin-bottom: 28px;
				width: 100%;
				tr{
					th
					,td{
						border-top: 1px solid rgba($col_theme_be03,0.3);
						border-left: none;
						border-right: none;
						border-bottom: none;
						padding: 12px 8px;
						text-align: left;
						font-size: 16px;
					}
					th{
						width: 1%;
						white-space: nowrap;
						background-color: transparent;
					}
					td{
						width: 100%;
						&.td_hissu{
							width: 1%;
							white-space: nowrap;
							span{
								display: inline-block;
								font-size: 12px;
								line-height: 100%;
								background-color: $col_theme_be02;
								padding: 4px 4px 3px;
								border-radius: 4px;
								color: #fff;
							}
						}
					}

					&:last-child{
						border-bottom: 1px solid rgba($col_theme_be03,0.3);
						th
						,td{
							&:last-child{
								// border-bottom: 1px solid rgba($col_theme01,0.3);
							}
						}
					}

					.wpcf7-list-item{
						display: block;
						margin: 0 0 8px 0;
						input[type=checkbox], input[type=radio]{
							-ms-transform: scale(1.2, 1.2);
							-webkit-transform: scale(1.2, 1.2);
							transform: scale(1.2, 1.2);
						}
					}
				}
			}
			.box_agree{
				text-align: left;

				.box_privacy{
					border: 1px solid rgba($col_theme_be03,0.3);
					padding: 20px;
					width :100%;
					height: 200px;
					overflow-y: scroll;
					font-size: 14px;
					margin-top: 20px;
					margin-bottom: 20px;
					h2{
						margin: 0px 0 0px 0;
						font-size: 18px;
						line-height: 160%;
						border-left: none;
						padding: 0;

					}
					h3{
						margin: 36px 0 10px 0;
						font-size: 16px;
						line-height: 160%;
						border-left: none;
						padding: 0;
						border-bottom: none;
					}
					p{
						font-size: 14px;
						line-height: 160%;
						margin-top: 12px;
					}
				}
				.box_ipt_agree{
					text-align: center;
				}
			}
			.box_submit{
				text-align: center;
				margin-top: 40px;
			}
			.box_notice{
				margin-top: 60px;
				p{
					line-height: 160%;
					margin-top: 2px;
				}
			}



			.works_content{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.works_content_left{
					width: 480px;
					a{
						display: inline-block;
						border: solid 1px $col_theme_be04;
						background-color: #fff;
						padding: 10px;
						img{
							max-width: 100%;
							vertical-align: bottom;
						}
						&:hover
						,&:focus
						,&:active {
							border: solid 1px $col_theme_be01;
						}
					}
				}
				.works_content_right{
					width: 640px;
					p{
						font-size: 16px;
						margin-top: 12px;
						line-height: 180%;
					}
					ul.works_asign{
						margin: 0;
					}
				}
				.tbl_works{
					margin: 0 0 40px 0;
				}
			}
			.works_end_date{
				text-align: center;
				margin-bottom: 80px;
			}

		}

	}

	h1,h2,h3,h4,h5,h6{
		margin-top: 50px;
		line-height: 140%;
		font-family: $font_serif;
	}
	h1{
		font-size: 30px;
		&.page_title{
			margin-top: 0px;
			margin-bottom: 30px;
			padding-bottom: 4px;
			border-bottom: solid 1px $bg_lgray;
		}
	}

	h2{
		font-size: 24px;
	}
	h3{
		font-size: 22px;
	}
	h4{
		font-size: 20px;
	}
	h5{
		font-size: 18px;
	}
	h6{
		font-size: 16px;
	}
	p{

		margin-top: 20px;
		line-height: 200%;
		text-align: justify;
		text-justify: inter-ideograph;
		&.has-text-align-center{
			text-align: center;
		}
		&.has-text-align-right{
			text-align: right;
		}
	}
	

	h3.h3_sub{
		font-size: 24px;
		line-height: 120%;
		letter-spacing: 0.2em;
		margin-top: 80px;
		font-weight: 400;
	}
	.box_sub_btn{
		margin-top: 20px;
		text-align: right;
	}
	figure.img_border{
		border: 1px solid rgba($col_theme_be03,0.3);
		margin: 1em 0 1em;
	}
	.page_nav
	,.post_nav{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 40px;
		margin-bottom: 40px;
		.wp-pagenavi {
			clear: both;
			text-align: center;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			a
			,span {
				text-decoration: none;
				width: 50px;
				height: 50px;
				padding: 0;
				margin: 4px;
				font-size: 18px;
				line-height: 100%;
				color: #fff;
				display: inline-block;
				border-radius: 100%;
				background-color: $col_theme_be02;
				vertical-align: middle;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}

			a.pages
			,span.pages {
				color: $col_dgray;
				background-color: transparent;
				white-space: normal;
				width: auto;
				margin-right: 12px;
				font-size: 15px;
			}
			a:hover
			,span.current {
				background-color: $col_theme_be04;
			}
			span.extend{
				color: $col_dgray;
				background-color: transparent;
			}
			a.previouspostslink
			,span.previouspostslink{
				background: url(../img/arrow_ss_wh_l.svg) no-repeat 50% 45% $col_theme_be02;
				background-size: 15px 30px;
				background-position: 44% 50%;
				text-indent: 300%;
				white-space: nowrap;
				overflow: hidden;
				&:hover
				,&:focus
				,&:active{
					background-color: $col_theme_be04;
				}
			}
			a.nextpostslink
			,span.nextpostslink{
				background: url(../img/arrow_ss_wh_r.svg) no-repeat 50% 45% $col_theme_be02;
				background-size: 15px 30px;
				background-position: 56% 50%;
				text-indent: 300%;
				white-space: nowrap;
				overflow: hidden;
				&:hover
				,&:focus
				,&:active{
					background-color: $col_theme_be04;
				}
			}
			a.last
			,span.last
			,a.first
			,span.first{
				font-size: 15px;
				font-weight: 100;
			}


		}
	}
}



/*-------------------------------------------------
	++ COMMON
-------------------------------------------------*/

.font_antro_vectra{
	font-family: $font_antro_vectra;
}


a.link_radius
,div.link_radius{
	font-family: $font_serif;
	font-size: 14px;
	line-height: 100%;
	border-radius: 24px;
	padding: 4px 28px;
	color: #fff;
	text-align: center;
	min-width: 160px;
	background-color: $col_theme_be03;

}
a.link_radius{
	background: url( "../img/arrow_m_wh_r.svg" ) no-repeat 90% 50%;
	background-color: $col_theme_be03;
	background-size: 8px 16px;
	&:hover
	,&:focus
	,&:active {
		text-decoration: none;
		background-color: $col_theme_be01;
		background-position: 95% 50%;
	}
}
div.link_radius{
	background-color: $col_theme_be01;
}





/*  Forms
--------------------------------------- */
input
,textarea
,select{
	font-family : "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	// font-family: $font_shippori;
	font-size: 16px;
	background-color: #ffffff;
}
input::-moz-focus-inner
,button::-moz-focus-inner {
	border			: 0;
	padding			: 0;
}
input[type="text"]
,input[type="email"]
,input[type="tel"]
,input[type="number"]
,input[type="password"]
,textarea
,select{
	padding: 8px;
	border: 1px solid $col_theme_be01;
}
input[type="date"]
,input[type="time"]
,input[type="datetime-local"]
,input[type="week"]
,input[type="month"]
,input[type="text"]
,input[type="email"]
,input[type="url"]
,input[type="password"]
,input[type="search"]
,input[type="tel"]
,input[type="number"]
,input[type="image"]
,textarea {
	border-radius: 0px;
	webkit-border-radius: 0px;
	-webkit-appearance: none;
}


input[type="search"] {
	-webkit-appearance	: textfield;
	-webkit-box-sizing	: content-box;
	-moz-box-sizing	: content-box;
	box-sizing		: content-box;
}

input[type="search"]:focus {
	outline-offset	: -2px;
}

input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="checkbox"]
,input[type="radio"] {
	box-sizing		: border-box;
	padding			: 0;
}

input[type="submit"]
,input[type="reset"]
,input[type="button"]
,button {
	cursor			: pointer;
	-webkit-appearance: button;
	*overflow		: visible;
}

textarea {
	vertical-align	: top;
	font-size		: 100%;
	resize			: none;
}

fieldset {
	border			: 0 none;
}

legend {
	display			: none;
}

label {
	cursor			: pointer;
}

select {
	line-height		: normal;
}



.ipt_size_full{
	width			: 100%;
}
.ipt_size_harf{
	width			: 45%;
	width: calc(50% - 10px);
}
.ipt_size_l{
	width			: 400px;
}
.ipt_size_m{
	width			: 200px;
}
.ipt_size_s{
	width			: 100px;
}
.ipt_size_ss{
	width			: 50px;
}


.ipt_row_l{
	height			: 300px;
}
.ipt_row_m{
	height			: 200px;
}
.ipt_row_s{
	height			: 80px;
}



input.btn_m{
	border: none;
	outline: none;
	position: relative;
	display: inline-block;
	background-color: $col_theme_be02;
	border-radius: 8px;
	padding: 12px 24px 12px 24px;
	box-shadow: 0px 5px 0px darken($col_theme_be02, 10%);
	transition: .2s;
	color: #fff;

	// font-family: $font_serif;

	font-size: 20px;
	font-weight: 500;
	line-height: 100%;
	letter-spacing: 0.1em;
	
	min-width: 240px;

	&:hover
	,&:focus,
	,&:active {
		outline: none;
		box-shadow: 0px 1px 0px darken($col_theme_be02, 10%);
		transform: translateY(4px);
		text-decoration: none;
	}

}



.ipt_category {
	margin: auto;
	display: block;
	margin-top: 4px;
}

/*Checkboxes styles*/
input[type="checkbox"] {
	display: none;
	+ .wpcf7-list-item-label {
		display: inline-block;
		position: relative;
		padding-left: 28px;
		// margin-bottom: 4px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;

		&:last-child {
			// margin-bottom: 0;
		}

		&:before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			border: 1px solid $col_theme_be01;
			position: absolute;
			left: 0;
			top: 2px;
			-webkit-transition: all .12s, border-color .08s;
			transition: all .12s, border-color .08s;
		}
	}


	&:checked + .wpcf7-list-item-label:before {
		width: 10px;
		top: -5px;
		left: 5px;
		border-radius: 0;
		opacity: 1;
		border: 2px solid $col_theme_be01;
		border-top-color: transparent;
		border-left-color: transparent;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}


.grecaptcha-badge{
	margin-bottom: 100px;
}


.wpcf7 form .wpcf7-response-output{
	margin: 2em 0em 1em;
}
.wpcf7-not-valid-tip{
	font-size: 15px;
}



.wpcf7-radio{
	.wpcf7-list-item {
		// margin: 0.5rem;
		input[type="radio"] {
			position: absolute;
			opacity: 0;
			+ .wpcf7-list-item-label {
				&:before {
					content: '';
					background: #fff;
					border-radius: 100%;
					border: 1px solid #999;
					display: inline-block;
					width: 1.4em;
					height: 1.4em;
					position: relative;
					top: 1px;
					opacity: 0.8;
					margin-right: 6px;
					margin-bottom: 6px;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
				}
			}
			&:checked {
				+ .wpcf7-list-item-label {
					&:before {
						opacity: 1;
						background-color: $col_theme_be01;
						box-shadow: inset 0 0 0 4px #fff;
					}
				}
			}
			&:focus {
				+ .wpcf7-list-item-label {
					&:before {
						// outline: none;
						border-color: $col_theme_be01;
					}
				}
			}
			+ .wpcf7-list-item-label {
				&:empty {
					&:before {
						margin-right: 0;
					}
				}
			}
		}
	}
}




/* animation */
@-webkit-keyframes passing-bar{
	0% {
		left: 0;
		right: auto;
		width: 0;
	}
	50% {
		left: 0;
		right: auto;
		width: 100%;
	}
	51% {
		left: auto;
		right: 0;
		width: 100%;
	}
	100% {
		left: auto;
		right: 0;
		width: 0;
	}
}
@keyframes passing-bar{
	0% {
		left: 0;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	51% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}
@-webkit-keyframes passing-txt{
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
@keyframes passing-txt{
	0% { opacity:0; }
	50% { opacity:0; }
	100% { opacity:1; }
}
/* css */
.passing .passing-box {
	display: block;
}
.passing .passing-bar {
	position: relative;
	display: inline-block;
	/*　後ほど解説　*/
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.passing .passing-bar:before {
	content: '';
	display: inline-block;
	width: 0;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	/* 任意の値 */
	background: #000;
}
.passing .passing-txt {
	opacity: 0;
	/* 後ほど解説 */
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	/* 任意の値 */
}
.passing.move .passing-bar:before {
	-webkit-animation: passing-bar 0.8s ease 0s 1 normal forwards;
	animation: passing-bar 0.8s ease 0s 1 normal forwards;
}
.passing.move .passing-txt {
	-webkit-animation: passing-txt 0s ease .5s 1 normal forwards;
	animation: passing-txt 0s ease .5s 1 normal forwards;
}




.wp-pagenavi{
	 display: flex;
	 margin: 40px 0;
	justify-content: center;
	a
	, span{
		color: #fff;
		background-color: $col_theme_be02;
		border: solid 1px $col_theme_be02;
		border-radius: 100%;
		min-width: 50px;
		height: 50px;
		margin: 4px;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 20px;
		line-height: 100%;
		font-family: $font_shippori;
		padding-bottom: 4px;
	    &.current{
			background-color: $col_theme_be01;
			border: solid 1px $col_theme_be01;
			font-weight:400;
	    }
		&.pages{
			// display: none;
		}
		&.nextpostslink
		,&.previouspostslink{
			background: url(../img/arrow_m_wh_r.svg) no-repeat 55% 50%;
			background-size: 12px 24px;
			background-color: $col_theme_be02;
			text-indent: 500%;
			white-space: nowrap;
			overflow: hidden;

			&:hover
			,&:focus
			,&:active {
				background-position: 75% 50%;
			}
		}

		&.previouspostslink{
			transform: rotateZ(180deg);
		}
		&:hover
		,&:focus
		,&:active {
			background-color: $col_theme_be01;
			border: solid 1px $col_theme_be01;
			text-decoration: none;
		}
	}
}





/*-------------------------------------------------
	++ CONTENTS
-------------------------------------------------*/



/*-------------------------------------------------
	++ FOOTER
-------------------------------------------------*/
footer{
	width: 100%;
	@at-root .admin-bar footer{
		padding-top: 54px;
	}
	.cta{
		// background-color: #fafafa;
		background-color: rgba(#f8f8f8, 0.5);
		padding: 60px 0;
		.cta_inner{
			@include center;
			width: $contet_width;
		}

		.txt_cta_copy{
			font-size: 24px;
			line-height: 160%;
			font-weight: 400;
			color: $col_theme_be02;
			letter-spacing: 0.15em;
			// margin-top: 8px;
			margin-bottom: 40px;
		}

		.box_btn{
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			a.btn_cta{
				display: block;
				width: 48%;
				// border: solid 1px $col_theme_be04;
				background-color: #fff;
				padding: 10px;
				text-align: center;
				font-family: $font_serif;
				color: $col_text;
				font-weight: 400;
				font-size: 18px;
				line-height: 160%;
				letter-spacing: 0.1em;
				position: relative;
				&:hover
				,&:focus
				,&:active {
					text-decoration: none;
					.btn_cta_inner{
						background-color: #fff;
					}
					.btn_cta_line_top
					,.btn_cta_line_left
					,.btn_cta_line_right
					,.btn_cta_line_bottom{
						@include hover_transition;
						&::before{
							background-color: $col_theme_be01;
						}
					}
					.btn_cta_line_top{
						border-top: solid 1px $col_theme_be01;
					}
					.btn_cta_line_left{
						border-left: solid 1px $col_theme_be01;
					}
					.btn_cta_line_right{
						border-right: solid 1px $col_theme_be01;
					}
					.btn_cta_line_bottom{
						border-bottom: solid 1px $col_theme_be01;
					}
					h2{
						color: $col_theme_be01;
					}
				}

				.btn_cta_inner{
					display: block;
					vertical-align: middle;
					height:auto;
					padding: 40px 10px 32px;
					background-color: $col_theme_be06;
					@include hover_transition;
				}

				h2{
					color:$col_theme_be03;
					font-size: 32px;
					line-height: 100%;
					letter-spacing: 0.2em;
					@include hover_transition;
					.ttl_jp{
						display: block;
						font-size: 14px;
						line-height: 100%;
						margin-top: 8px;
						letter-spacing: 0.2em;
					}
				}
				p{
					margin-top: 28px;
					font-size: 15px;
					line-height: 180%;
				}

				.btn_cta_line_top
				,.btn_cta_line_left
				,.btn_cta_line_right
				,.btn_cta_line_bottom{
					position: absolute;
					@include hover_transition;
					&::before{
						content: '';
						position: absolute;
						width: 5px;
						height: 5px;
						background-color: $col_theme_be03;
						border-radius: 100%;
						@include hover_transition;
					}
				}
				.btn_cta_line_top{
					border-top: solid 1px $col_theme_be03;
					width: calc(100% + 20px);
					top: -1px;
					left: -10px;
					&::before{
						top: -3px;
						left: 7px;
					}
				}
				.btn_cta_line_left{
					border-left: solid 1px $col_theme_be03;
					height: calc(100% + 20px);
					top: -10px;
					left: -1px;
					&::before{
						bottom: 7px;
						left: -3px;
					}
				}
				.btn_cta_line_right{
					border-right: solid 1px $col_theme_be03;
					height: calc(100% + 20px);
					top: -10px;
					right: -1px;
					&::before{
						top: 7px;
						right: -3px;
					}
				}
				.btn_cta_line_bottom{
					border-bottom: solid 1px $col_theme_be03;
					width: calc(100% + 20px);
					bottom: -1px;
					left: -10px;
					&::before{
						bottom: -3px;
						right: 7px;
					}
				}
			}
		}
	}

	.site_link{
		background-color: $col_theme_be06;
		padding: 60px 0;
		.site_link_inner{
			@include center;
			width: $contet_width;

			display: flex;
			align-items: stretch;
			justify-content: space-between;
		}
		.site_about{
			width: 50%;
			text-align: left;
			.logo_footer{
				width: 300px;
			}
			.txt_site_about{
				font-size: 14px;
				line-height: 180%;
				margin-top: 12px;
				margin-left: 134px;

			}
			.footer_sub_menu{
				border-top: solid 1px $col_theme_be05;
				width: 300px;
				padding-top: 16px;
				margin-top: 20px;
				margin-left: 134px;
				a{
					font-size: 12px;
					line-height: 160%;
					color: $col_text;
					background: url(../img/arrow_sss_be_r.svg) no-repeat 95% 50%;
					background-size: 8px 16px;
					padding: 1px 20px 1px 0;
					display: inline-block;
					&:hover
					,&:focus
					,&:active {
						text-decoration: none;
						// background-color: $col_theme_be01;
						background-position: 100% 50%;
					}
				}
			}
		}
		.site_map{

			display: flex;
			align-items: stretch;
			justify-content: space-between;

			width: 50%;
			ul.site_link01
			,ul.site_link02{
				width: 206px;
				li{
					border-left: solid 1px $col_theme_be03;
					text-align: left;
					padding: 0px 0px;
					margin: 0 0 20px 0;
					font-size: 16px;
					line-height: 120%;
					letter-spacing: 0.3em;
					&:last-child{
						margin-bottom: 0;
					}
					a{
						padding: 4px 16px;
						color: $col_text;
						display: block;
						&:hover
						,&:focus
						,&:active {
							text-decoration: none;
							background-color: $col_theme_be05;
						}
					}
					.txt_jp{
						font-size: 12px;
						line-height: 120%;
						font-weight: 500;
						display: block;
						letter-spacing: 0.2em;
						margin-top: 4px;
					}
				}
			}

			.box_bnr{
				margin-left: 10px;
				width: 200px;
				text-align: right;
				display: flex;
				flex-direction: column;
				img{
					vertical-align: bottom;
					margin-bottom: 8px;
				}
			}
		}
	}
	.copyright{
		text-align: center;
		font-family: $font_sunserif;
		font-size: 12px;
		line-height: 120%;
		font-weight: 300;
		padding: 12px 0;
		background-color: rgba(#fff,0.9);
	}
	.footer_inner {
		@include center;
		margin-top: 1px;
		width: $contet_width;
		padding: 10px;
		position: relative;
	}
}


#page_top{
	display: block;
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 64px;
	height: 64px;
	border-radius: 100%;
	background: url( "../img/arrow_s_wh_t.svg" ) no-repeat 50% 45%;
	background-size: 40px 20px;
	background-color: $col_theme_be03;

	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	&:hover
	,&:focus
	,&:active {
		text-decoration: none;
		background-color: $col_theme_be01;
		background-position: 50% 35%;
	}
}


#box_svg{
	width: 700px;
	// height: 100%;
	position: absolute;
	top: -70px;
	right: 0;
	z-index: -100;
    overflow: hidden;
	&.fixed{
		position: fixed;
		top: 0px;
	}
	img{
		max-width: unset;
	}
}



@media (max-width: 1200px) {
	header#site_header{
		// display: block;
		nav.main_menu{
			// display: block;
			// width: 100%;
			margin-top: 12px;
			ul{
				&:before{
					
				}
				&:after{
					display: none;
				}
				li{
					a
					,a:link
					,a:visited {
						font-size: 12px;
						padding: 28px 12px 28px 12px;
					}
					&.link_red{
						a
						,a:link
						,a:visited {
							font-size: 14px;
							padding: 20px 20px;
							.txt_jp {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
	#main_image{
		height: auto;
		.main_image_inner{
			height: auto;
		}
	}

	#contener{
		#sec_status{
			.sec_status_inner{
				flex-wrap: wrap;
				.block_status02{
					width: calc(100% - ( 120px + 40px ));
					margin-bottom: 20px;
					p{
						br{
							display: none;
						}
					}
					+ .block_status_hr{
						display: none;
					}
				}
				.block_status_hr{
					margin-top: 12px;
				}
				.block_status03{
					width: 45%;
					margin-top: 12px;
				}
				.block_status04{
					width: 45%;
					margin-top: 12px;
				}
			}
		}
		#contents{
			.width_l{
				width: 94%;
			}


			.box_left_right{
				display: block;
				.block_ttl{
					position: relative;
					width: 100%;
					margin-bottom: 40px;
					p{
						margin-top: 32px;
						br{
							display: none;
						}
					}
					.sec_icatch{
						position: absolute;
						right: -8px;
						top: -80px;
						margin-top: 0;
						z-index: -1;
						img {
							width: 240px;
						}
					}
				}
				.block_content{
					width: 100%;
				}
			}


			#sec_commit{
				.block_content{
					h3{
						margin-top: 60px;
					}
				}
			}

		}
	}
	footer{
		.cta{
			.cta_inner{
				width: 94%;
			}
		}
		.site_link{
			.site_link_inner{
				width: 94%;
			}
		}
	}
}


@media (max-width: 1000px) {
	header#site_header{
		#site_name{
			margin-top: 28px;
		}
		nav.main_menu{
			ul{
				li{
					width: calc((100%) / 5);
					a
					, a:link
					, a:visited{
						padding: 14px 6px 14px 6px;
					}
					&.link_red{
						width: calc((100%) / 5 * 2);
						a
						, a:link
						, a:visited{
							padding: 8px 6px 8px 6px;
							.txt_jp{
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
	#main_image{
		.main_image_inner{
			.capthcopy{
				min-width: auto;
				margin-right: 20px;
				strong.sub_copy{
					font-size: 1.7vw;
				}
				.box_h2{
					margin-bottom: 20px;
				}
				h2{
					font-size: 4.3vw;
					.txt_s{
						font-size: 3.4vw;
					}
				}
				.box_p{
					margin: 12px 0;
				}
				p{
					font-size: 1.6vw;
				}
			}

			.left{
				margin-left: 6vw;
				.txt_texture{
					left: 0vw;
					height: 80px;
				}
			}
		}
	}
	#contener{
		padding-top: 100px;
		#contents{
			.width_l
			,.width_m
			,.width_s{
				width: 96%;
			}
		}
	}
	footer{
		.cta{
			.txt_cta_copy{
				font-size: 20px;

			}
			.box_btn{
				a.btn_cta{
					p{
						br{
							display: block;
						}
					}
				}
			}
		}
		.site_link{
			.site_link_inner{
					display: block;
			}
			.site_about{
				width: 100%;
			}
			.site_map{
				margin-top: 80px;
				padding-left: 134px;
				width: 100%;
				flex-wrap: wrap;
				ul.site_link01
				, ul.site_link02{
					width: 50%;
				}
				.box_bnr{
					width: auto;
					margin-left: 0;
					margin-top: 40px;
				}
			}
		}

	}
}



@media (max-width: 767px) {

	.sp_only{
		display: block;
	}
	.pc_only{
		display: none;
	}
	.sp_only_inline{
		display: inline-block;
	}
	.pc_only_inline{
		display: none;
	}

	#wpadminbar {
		position: fixed;
	}

	.mask_logo_def{
		min-width: auto;
		min-height: auto;
		width: 100%;
	}
	.mask_logo_pic{
		min-width: auto;
		min-height: auto;
		width: 100%;
		position: relative;
	}

	header#site_header{
		display: block;
		min-height: 62px;
		&.UpMove{
			animation: UpAnime 0.5s forwards;
		}

		@keyframes UpAnime{
			from {
				opacity: 1;
				transform: translateY(0);
			}
			to {
				opacity: 0;
				transform: translateY(-100px);
			}
		}

		/*　下に下がる動き　*/

		&.DownMove{
			animation: DownAnime 0.5s forwards;
		}
		@keyframes DownAnime{
			from {
				opacity: 0;
				transform: translateY(-100px);
			}
			to {
				opacity: 1;
			transform: translateY(0);
			}
		}

		#site_name{
			width: 120px;
			height: 42px;
			margin: 12px 0 8px 12px;
		}


		#nav-toggle {
			-webkit-transition: all 0.3s ease-in;
			-moz-transition: all 0.3s ease-in;
			-ms-transition: all 0.3s ease-in;
			-o-transition: all 0.3s ease-in;
			transition: all 0.3s ease-in;

			z-index: 990;
			display: block;
			position: absolute;
			cursor: pointer;
			background-color: rgba($col_theme_be02, 1);
			// margin: 1px;
			width: 64px;
			bottom: 10px;
			right: 0;
			> div.menu_bar {
				position: relative;
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 auto;
				padding: 10px;
				// margin-top: 5px;
			}
			span {
				width: 24px;
				height: 1px;
				// left: 20%;
				display: block;
				background: #fff;
				position: absolute;
				transition: transform .6s ease-in-out, top .5s ease;

				&:nth-child(1) {
					top: 10px;
				}
				&:nth-child(2) {
					top: 18px;
					width: 18px;
				}
				&:nth-child(3) {
					top: 26px;
					width: 12px;
				}
			}

		}

		@at-root .open header#site_header {
			#nav-toggle{
				bottom: 0px;
				span {
					&:nth-child(1) {
						top: 20px;
						width: 24px;
						transform: rotate(45deg);
					}
					&:nth-child(2) {
						top: 20px;
						width: 0;
						left: 50%;
					}
					&:nth-child(3) {
						top: 20px;
						width: 24px;
						transform: rotate(-45deg);
					}
				}
			}

		}





		nav.main_menu {
			// border-top: solid 1px $col_theme01;
			// border-bottom: solid 1px $col_theme01;
			background-color: rgba(#fff,0.98);
			position: absolute;
			// position: relative;

			top: 0px;
			left: 0;
			right: 0;
			// bottom: 0;

			padding: 0px 0px 10px 0;
			padding: 70px 5% 0;

			height: 100vh;

			z-index: 980;
			text-align: left;
			display: block;
			visibility: hidden;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			opacity: 0;
			transition: opacity .6s ease, visibility .6s ease;
			overflow: hidden;

			opacity: 0;
			visibility: hidden;
			transition: 1000ms;
			ul{
				&:before{
					display: none;
				}
				li{
					width: 48%;
					a
					, a:link
					, a:visited{
						font-size: 16px;
						border-bottom: solid 1px $col_theme_be03;
						padding: 28px 4px 20px 4px;
						.txt_jp{
							display: block;
							position: static;
							margin-top: 4px;
							font-size: 12px;
						}
					}
					&.link_red{
						width: 100%;
						margin-top: 40px;
						a
						, a:link
						, a:visited{
							border-bottom: none;
							font-size: 17px;
							padding: 18px 6px 12px 6px;
							.txt_jp{
								font-size: 12px;
								margin-top: 4px;
							}
						}
					}

					@at-root header#site_header nav ul li.current-menu-item {
						border-bottom: none;
					}
					@at-root .single-price header#site_header nav ul li.price{
						border-bottom: none;
					}
				}
			}
		}

		@at-root .open header#site_header nav.main_menu {
			visibility: visible;
			opacity: 1;
			// overflow: hidden;
			overflow: scroll;
			display: block;

			transition: 1000ms;
		}

	}




	#main_image{
		padding: 80px 0 0px 0;
		height: auto;
		.main_image_inner{
			display: block;
			.left{
				margin-right: 0;
				margin-left: 0;
				.logomark{
					margin: 0 0px 0 24vw;
					// margin-bottom: 40px;
					// margin-left: 0;
					width: 70%;
					height: 72vw;
					min-width: auto;
					min-height: auto;
				}
				.logomark_outer{
					.box_illust_person{
						width: 24vw;
						height: 24vw;
						left: 38vw;
						top: 20vw;
					}
				}
				.txt_texture{
					left: 20px;
					font-size: 10px;
					bottom: 4vw;
					strong{
						font-size: 14x;
					}
				}
			}

			.capthcopy{
				width: 100%;
				min-width: auto;
				padding-left: 20px;
				margin-top: 20px;
				margin-bottom: 40px;
				strong.sub_copy{
					font-size: 13px;
					letter-spacing: 0;
					text-align: center;
					display: block;
					margin-top: 0px;
				}
				.box_h2{
					margin: 4px 0 4px;
				}
				h2{
					font-size: 30px;
					.txt_s{
						font-size: 24px;
					}
				}
				.box_p{
					margin: 8px 0;
				}
				p{
					font-size: 14px;
					letter-spacing: 0.04em;
					margin: 0px 0px 4px 4px;
				}
			}
		}
	}
	#contener{
		padding-top: 62px;
		.page_header{
			height: 200px;
			.page_header_title{
				font-size: 32px;
				.ttl_jp{
					font-size: 16px;
				}
			}
		}

		#contents{
			padding-bottom: 40px;
			.pankuzu{
				font-size: 11px;
			}
			.width_l
			,.width_m
			,.width_s{
				width: 88%;
			}
			.box_left_right{
				display: block;
				.block_ttl{
					width: 100%;
					margin-bottom: 40px;
					h2{
						font-size: 30px;
						.ttl_jp{
							font-size: 16px;
							margin: 2px 0 0 0px;
						}
					}
					p{
						font-size: 14px;
						line-height: 180%;
						margin-top: 20px;
					}
				}
				.block_content{
					width: 100%;
				}
			}

			.box_list_thumb{
				.block_list_thumb{
					display: block;
					a.list_thumb_pic{
						width: 100%;
						padding: 4px;
					}
					.list_thumb_txt{
						width: 100%;
						h3{
							letter-spacing: 0;
							margin-top: 8px;
							font-size: 18px;
						}
						.txt_client{
							font-size: 12px;
							margin-top: 8px;
						}
						.txt_entry_date{
							font-size: 12px;
							margin-top: 6px;
						}
						ul{
							margin-top: 16px;
							li{
								margin: 4px 0;
							}
						}
					}
				}
			}
			.box_list{
				a.block_list{
					background-size: 20px 40px;
					padding: 40px 32px 40px 0;
				}
				.block_list{
					.lbl_sub{
						font-size: 14px;
						margin-bottom: 4px;
					}
					h3{
						font-size: 14px;
					}
					p{
						letter-spacing: 0;
						font-size: 14px;
						line-height: 180%;
						margin-top: 12px;
						br{
							display: none;
						}
					}
				}
			}
			.box_tail_thumb{
				.block_tail_thumb{
					width: 48%;
					&.block_works_9
					,&.block_column_9{
						display: none;
					}
					.line_frame{
						font-size: 14px;
						padding: 4px;
						letter-spacing: 0;
						.line_frame_inner{
							width: 100%;
							height: 100px;
							// display: block;
							display:flex;
							justify-content:center;
							align-items:center;
						}
					}
					.txt_date{
						font-size: 10px;
						margin-top: 4px;
					}
				}
			}

			#sec_status{
				margin-bottom: 0;
				.sec_status_inner{
					display: block;
					padding: 20px;
					.block_status01{
						margin: 0 auto;
						width: 100%;
						h2{
							font-size: 20px;
							line-height: 100%;
							padding-top: 8px;
							padding-bottom: 6px;
							br{
								display: none;
							}
						}
					}
					.block_status02{
						width: 100%;
						padding: 0;
						margin-top: 20px;
						p{
							line-height: 180%;
							font-size: 13px;
							br{
								display: none;
							}
						}
					}
					.block_status03{
						width: 100%;
						margin-top: 20px;
						table{
							tr{
								td{
									padding-top: 6px;
									font-size: 12px;
									&.td_val{
										.txt_val{
											font-size: 22px;
										}
										.txt_unit{
											font-size: 12px;
										}
									}
								}
							}
						}
					}
					.block_status04{
						width: 100%;
						margin-top: 20px;
						margin-bottom: 8px;
						h3{
							font-size: 10px;
						}
						.chart{
							justify-content: center;
							.chart_bg{
								width: 140px;
								.chart_bar{
									width: 140px;
								}
							}
							.txt_chart_val{
								font-size: 20px;
							}
						}
						.message{
							display: block;
							height: auto;
							p{
								font-size: 12px;
								margin: 0;
								strong{
									margin: 0;
								}
							}
						}
					}
				}
			}
			#sec_commit{
				padding-top: 60px;
				@at-root .admin-bar #contener #contents #sec_commit{
					padding-top: 80px;
				}
				.block_content{
					h3{
						font-size: 18px;
						.font_antro_vectra{
							font-size: 50px;
							margin-bottom: -16px;
							margin-right: 12px;
							margin-left: 8px;
						}
					}
					.commit_txt{
						p{
							font-size: 15px;
							line-height: 200%;
							letter-spacing: 0;
							br{
								display: none;
							}
						}
					}
					.profile{
						display: block;
						.profile_photo{
							margin: 0 auto;
						}
						.profile_txt{
							width: 100%;
							margin-left: 0;
							.txt_post{
								text-align: center;
								// padding-left: 0.4em;
							}
							.txt_name{
								display: block;
								text-align: center;
								.txt_name_jp{
									padding-left: 0.8em;
								}
								.txt_name_en{
									margin-left: 0;
									text-align: center;
								}
							}
							p{
								font-size: 13px;
								text-align: center;
								br{
									display: block;
								}
							}
						}
					}
				}
			}

			#sec_service{
				.block_ttl{
					position: relative;
					p{
						br{
							display: block;
						}
					}
					.sec_icatch{
						position: absolute;
						right: -18px;
						top: -12px;
						margin-top: 0;
						z-index: -1;
						img{
							width: 160px;
						}
					}
				}
				.block_content{
					h3{
						font-size: 24px;
						.lbl_jp{
							font-size: 12px;
							margin-top: 6px;
							margin-left: 8px;
						}
					}

				}
			}

			#sec_price{
				.block_content{
					.box_list{
						.block_list{
							h3{
								font-size: 20px;
							}
						}
						a.block_list{
							padding: 40px 32px 40px 0;
							background-size: 20px 40px;
						}
					}
				}
			}

			#sec_news{
				.block_content{
					.box_list{
						.block_list{
							p{
								font-size: 13px;
							}
						}
					}
				}
			}


			.header_post_title
			, .header_page_title{
				margin-top: 60px;
				margin-bottom: 40px;
				h1{
					font-size: 20px;
				}
				.plan_outline{
					font-size: 16px;
				}
			}
			.post_content
			,.page_content{
				margin-top: 60px;
				h2{
					font-size: 20px;
					line-height: 140%;
					padding: 2px 0 0 8px;
				}
				h3{
					font-size: 18px;
					line-height: 140%;
					padding-bottom: 4px;
				}
				p{
					font-size: 14px;
				}
				table{
					&.tbl_works{
						border-bottom: 1px solid $col_theme_be03;
						tr{
							border-top: 1px solid $col_theme_be03;
							th
							,td{
								width: 100%;
								display: block;
								text-align: left;
								padding: 12px 0;
								font-size: 15px;
							}
							th{
								border-bottom: none;
								border-top: none;
								padding-top: 18px;
								padding-bottom: 4px;
								font-size: 12px;
								line-height: 120%;
							}
							td{
								padding-top: 2px;
								padding-bottom: 16px;
								border-top: none;
								border-bottom: none;
								&.td_break_all{
									word-break: break-all;
								}
							}
						}
					}
					&.tbl_form{
						tr{
							border-top: 1px solid $col_theme_be03;
							th
							,td{
								width: 100%;
								background-color: #fff;
								display: block;
								text-align: left;
								padding: 12px 8px;
								font-size: 15px;
								border: none;
							}
							th{
								width: auto;
								display: inline-block;
								float: left;
								padding: 12px 8px 4px 8px;
							}
							td{
								padding-top: 0px;
								padding-bottom: 16px;
								float: left;
								clear: both;
								&.td_hissu{
									clear: none;
									float: left;
									padding: 12px 8px 4px 12px;
								}
							}
						}
					}
					&.tbl_underline{
						tr{
							th
							,td{
								padding: 12px;
							}
						}
					}
				}
				ul{
					&.works_asign{
						margin-top: 8px;
						li{
							margin-bottom: 4px;
						}
					}
				}


				.works_content{
					display: block;
					.works_content_left{
						width: 100%;
						a{
							padding: 4px;
						}
					}
					.works_content_right{
						width: 100%;
						margin-top: 40px;
						ul.works_asign{
							margin-top: 8px;
						}
					}
				}
				.box_notice{
					margin-top: 20px;
				}
			}

			.box_link_term{
				display: block;
				.block_link_term{
					width: 100%;
					display: block;
					margin: 8px 0;
				}
			}


		}
	}



	.wp-pagenavi{
		a
		,span{
			min-width: 40px;
			height: 40px;
			margin: 2px;
		}
	}

	.wpcf7-spinner{
		display: block;
		margin: 20px auto 0;
	}


	.admin-bar footer{
		padding-top: 0;
	}

	footer{
		.cta{
			padding: 40px 0;
			.cta_inner{
				width: 100%;
			}
			.txt_cta_copy{
				font-size: 16px;
				margin-bottom: 28px;
			}
			.box_btn{
				display: block;
				a.btn_cta{
					width: 86%;
					margin: 0 auto 32px;
					padding: 6px;
					h2{
						font-size: 24px;
					}
					p{
						margin-top: 20px;
						font-size: 14px;
					}
				}


			}
		}
		.site_link{
			.site_link_inner{
				width: 90%;
				display: block;
			}
			.site_about{
				width: 100%;
				.txt_site_about{
					margin-left: 4%;
				}
				.footer_sub_menu{
					margin-left: 4%;
					margin-bottom: 20px;
				}
			}
			.site_map{
				flex-wrap: wrap;
				width: 92%;
				margin: 40px auto 0;
				padding-left: 0;
				ul{
					&.site_link01
					,&.site_link02{
						width: 50%;
						li{
							font-size: 16px;
							.txt_jp{
								margin-top: 2px;
								font-size: 12px;
							}
							a{
								padding: 4px 0px 4px 12px;
							}
						}
					}
				}
				.box_bnr{
					margin: 40px 0 0 0;
					width: 100%;
					text-align: left;
					flex-direction: row;
					flex-flow: row wrap;
					justify-content: space-between;
					a{
						width: 48%;
					}
				}
			}
		}
		.copyright{
			font-size: 10px;
			padding: 6px 0;
		}
	}
	#box_svg{
		width: 100%;
		left: 0;
		right: auto;
		img{
			opacity: 0.5;
			width: 700px;
		}
	}
	#page_top{
		width: 50px;
		height: 50px;
		bottom: -4px;
		right: -4px;
	    background-size: 30px 15px;
	}

	.grecaptcha-badge{
		margin-bottom: 50px;
	}


}



@media print{
	#pageTop {
		display:none!important;
	}
}